import { Button, notification, Space } from "antd";
import React, { ReactNode } from "react";
import * as H from 'history';
import { v4 as uuid } from "uuid";
import Env from "env";

export type GetFlashQueryProps = (query: URLSearchParams) => {
    key?: string;
    history: H.History;
    message: ReactNode;
    description?: ReactNode;
    type?: "success" | "error" | "info" | "warning";
    duration?: number;
    onClose?: () => void;
} | void;

export default class Notifications {
    public flashQuery(getProps: GetFlashQueryProps): void {
        if (window.location.search) {
            const params = new URLSearchParams(window.location.search);
            const props = getProps(params);
            if (!props) return;

            const key = props.key || uuid();

            notification.open({
                key: key,
                message: props.message,
                description: props.description,
                type: props.type,
                duration: props.duration || 0,
                btn: (
                    <Button
                        onClick={() => notification.close(key)}
                    >Close</Button>
                )
            });

            props.history?.replace(window.location.pathname);
        }
    }

    public showSuccess(title: React.ReactNode, description: React.ReactNode, duration?: number) {
        notification.success({
            key: 'success-notification',
            message: title,
            description: description,
            duration: duration || 0,
            btn: (
                <Button
                    onClick={() => notification.close("success-notification")}
                >Close</Button>
            )
        })
    }

    public showError(title: React.ReactNode, description: React.ReactNode) {
        notification.error({
            key: "checkout-error",
            message: title,
            description: description,
            type: "error",
            duration: 0,
            btn: (
                <Space>
                    <Button
                        type="link"
                        onClick={() => window.open(Env.DISCORD_SUPPORT_LINK, "_blank")}
                    >Support</Button>

                    <Button
                        onClick={() => notification.close("checkout-error")}
                    >Close</Button>
                </Space>
            )
        })
    }
}
