class Random {
    public int(max: number): number;
    public int(min:number, max: number): number;

    public int(first: number, second?: number): number {
        if (!second) {
            const max = first;
            return Math.floor(Math.random() * max);
        }
        const min = first;
        const max = second;
        return Math.floor(Math.random() * max + min);
    }
}

export default new Random();