import Env from "env";
import { useRouteMatch } from "react-router-dom";
import { useGetHighscoreMiscQuery, useGetHighscoresBaseQuery } from "services/api/GraphqlTypes";
import useSearchQuery from "services/hooks/useSearchQuery";
import StringUtils from "services/utils/StringUtils";
import HighscoreTable from "./HighscoreTable";

interface IRouteProps {
    name?: string;
}

const HighscoreMiscTableRoute: React.FC = () => {
    const query = useSearchQuery();
    const match = useRouteMatch<IRouteProps>();
    const { data: baseData } = useGetHighscoresBaseQuery();

    const gameMode = query.get("mode") || baseData?.gameModes[0].name || "normal";
    const category = match.params.name || baseData?.highscoreMiscCategories[0].name || "";
    const search = query.get("search") || undefined;
    const page = query.get("page") || undefined;

    const { data, loading } = useGetHighscoreMiscQuery({
        variables: {
            perPage: Env.HIGHSCORES_PER_PAGE,
            gameMode,
            name: category,
            search: search,
            pageToken: page
        }
    });

    return (
        <HighscoreTable
            title={StringUtils.ucWords(category)}
            data={data?.highscoreMisc?.data || []}
            pagination={data?.highscoreMisc.paginatorInfo}
            loading={loading}
        />
    );
};

export default HighscoreMiscTableRoute;
