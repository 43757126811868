import { Space } from 'antd';
import React, { CSSProperties } from 'react';
import goldSrc from 'assets/images/store/gold.png';

interface Props {
    suffix?: React.ReactNode;
    prefix?: React.ReactNode;
    size?: 'small' | 'middle' | 'large' | number;
    gapSize?: 'small' | 'middle' | 'large' | number;
    direction?: 'vertical' | 'horizontal';
    marginLeft?: string | number;
    marginRight?: string | number;
    marginTop?: string | number;
    marginBottom?: string | number;
    style?: CSSProperties;
}

const GoldIcon: React.FC<Props> = ({ suffix, prefix, size, gapSize, direction, style, ...margins }) => {
    let imgWidth = 16;
    if (typeof size === "string") {
        switch (size) {
            case "middle":
                imgWidth = 24;
                break;
            case "large":
                imgWidth = 32;
                break;
            default:
                break;
        }
    } else if (typeof size === "number") {
        imgWidth = size;
    }

    const styl = {
        ...(style || {}),
        ...(margins || {})
    };

    if (suffix == null && prefix == null) {
        return <img alt="gold" width={imgWidth} height={imgWidth} src={goldSrc} style={styl} />;
    }

    return (
        <Space size={gapSize} direction={direction} align="center" style={styl}>
            {prefix && renderFix(prefix)}
            <img alt="gold" width={imgWidth} height={imgWidth} src={goldSrc} />
            {suffix && renderFix(suffix)}
        </Space>
    );
}

const renderFix = (node: React.ReactNode) => {
    if (typeof node === "string" || typeof node === "number") {
        return <span>{node}</span>;
    }
    return node;
}

export default GoldIcon;