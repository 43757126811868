import { ApolloProvider } from "@apollo/client";
import { Content } from "antd/lib/layout/layout";
import Env from "env";
import { Observer } from "mobx-react-lite";
import { useRouteMatch } from "react-router-dom";
import GraphQL from "services/api/GraphQL";

const AppContent: React.FC = ({ children }) => {
    const match = useRouteMatch({
        path: "/",
        exact: true
    });

    const mainStyles: React.CSSProperties = match ? {} : {
        backgroundImage: `url(${Env.BACKGROUND_IMAGE})`,
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#000",
        backgroundSize: "100%"
    };

    return (
        <Content
            className="height-relative"
            style={{
                ...mainStyles,
                overflow: "hidden"
            }}
        >
            <Observer>{() => (
                <ApolloProvider
                    client={GraphQL.client}
                >
                    {children}
                </ApolloProvider>
            )}</Observer>
        </Content>
    )
}

export default AppContent;