export const isDevelopment = process.env.REACT_APP_STAGING === 'true';
export const isProduction = !isDevelopment;

const APP_NAME = process.env.REACT_APP_NAME;

const APP_URL = isDevelopment ? 'http://localhost:3000' : process.env.REACT_APP_APP_URL;
const API_URL = isDevelopment ? 'http://localhost:8000' : process.env.REACT_APP_API_URL;

const BACKGROUND_IMAGE = process.env.REACT_APP_IMAGE_BG;
const BACKGROUND_COLOR = process.env.REACT_APP_BG_COLOR;
const BACKGROUND_SIZE = process.env.REACT_APP_BG_SIZE;

const DISCORD_LINK = process.env.REACT_APP_DISCORD_LINK || "https://discord.gg/MrBnXQRGWn";
const DISCORD_SUPPORT_LINK = process.env.REACT_APP_DISCORD_SUPPORT_LINK || "https://discord.gg/taSQeK4xN2";

const APP_TITLE = process.env.REACT_APP_TITLE;
const GAME_TYPE = process.env.REACT_APP_GAME_TYPE;

const PUSHER_PUBLIC_KEY = process.env.REACT_APP_PUSHER_PUBLIC_KEY || "";
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER || "";

const HIGHSCORES_PER_PAGE = Number(process.env.REACT_APP_HIGHSCORES_PER_PAGE || "25");

const ENABLE_STORE = Boolean(process.env.REACT_APP_ENABLE_STORE);

const Env = {
    APP_URL,
    API_URL,
    DISCORD_LINK,
    DISCORD_SUPPORT_LINK,
    APP_NAME,
    BACKGROUND_IMAGE,
    BACKGROUND_COLOR,
    BACKGROUND_SIZE,
    APP_TITLE,
    PUSHER_PUBLIC_KEY,
    PUSHER_CLUSTER,
    GAME_TYPE,
    HIGHSCORES_PER_PAGE,
    ENABLE_STORE
}

export default Env;