import { observable } from "mobx";
import { useObserver } from "mobx-react-lite";

const visible = observable.box(false);

const usePurchaseGoldModel = () => {
    return useObserver(() => ({
        visible: visible.get(),
        open: () => visible.set(true),
        close: () => visible.set(false)
    }));
}

export default usePurchaseGoldModel;
