import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import VerticalRightOutlined from "@ant-design/icons/lib/icons/VerticalRightOutlined";
import { Button, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import PlayerUsername from "components/Common/PlayerUsername";
import { useMemo } from "react";
import { CursorPaginator } from "services/api/GraphqlTypes";
import ArrayUtils from "services/utils/ArrayUtils";
import NumberUtils from "services/utils/NumberUtils";
import StringUtils from "services/utils/StringUtils";
import UrlUtils from "services/utils/UrlUtils";

interface IHighscoreTableProps {
    title: string;
    loading: boolean;
    data: DataType[];
    pagination?: CursorPaginator;
}

function getKeyName(key: string) {
    if (key === "value") {
        return "score";
    }
    return key;
}

const allowedKeys = ["rank", "username", "level", "experience", "score"];

const HighscoreTable: React.FC<IHighscoreTableProps> = ({ title, data, pagination, loading }) => {
    const pushQuery = UrlUtils.usePushQuery();

    const formattedData = useMemo(() => {
        return data.map((x, i) => {
            const item: DataType = { rank: i + 1 };
            Object.keys(x).forEach(key => {
                if (typeof x[key] === "string") {
                    item[getKeyName(key)] = StringUtils.ucWords(x[key]);
                } else {
                    item[getKeyName(key)] = x[key];
                }
            });
            return item;
        });
    }, [data]);

    const columns: ColumnsType<DataType> = useMemo(() => {
        const cols: ColumnsType<DataType> = [];

        const firstRow = formattedData[0];
        if (!firstRow) {
            return [];
        }

        for (const [key,] of Object.entries(firstRow)) {
            if (!ArrayUtils.contains(allowedKeys, key)) {
                continue;
            }

            cols.push({
                title: StringUtils.camelCaseToWords(key),
                dataIndex: key,
                align: "center"
                // sorter: typeof value === "number"
                //     ? (a, b) => sorterNumber(a[key] as number, b[key] as number)
                //     : (a, b) => sorterString(a[key] as string, b[key] as string)
            });

            if (key === "username") {
                cols[cols.length - 1].render = (text, row: DataType, index) => {
                    return (
                        <PlayerUsername
                            mode={row.gameMode}
                            rank={row.gameRank}
                        >{text}</PlayerUsername>
                    );
                }
            } else if (key === "experience" || key === "level" || key === "score") {
                cols[cols.length - 1].render = (text, row: DataType, index) => {
                    return NumberUtils.commaSeparated(text);
                }
            }
        }

        return cols;
    }, [formattedData]);

    return (
        <>
            <Table
                rowKey="rank"
                loading={loading}
                columns={columns}
                dataSource={formattedData}
                title={currentPageData => {
                    return title;
                }}
                pagination={false}
            />
            {pagination && (
                <Space style={{ marginTop: 16, marginBottom: 16, width: "100%", justifyContent: "end" }}>
                    <Button
                        icon={<VerticalRightOutlined />}
                        disabled={!pagination.prevPageToken}
                        onClick={() => {
                            pushQuery(query => query.delete("page"));
                        }}
                    />

                    <Button
                        icon={<LeftOutlined />}
                        disabled={!pagination.prevPageToken}
                        onClick={() => {
                            pushQuery(query => query.set("page", pagination.prevPageToken!));
                        }}
                    />

                    <Button
                        icon={<RightOutlined />}
                        disabled={!pagination.nextPageToken}
                        onClick={() => {
                            pushQuery(query => query.set("page", pagination.nextPageToken!));
                        }}
                    />
                </Space>
            )}
        </>
    );
}

type DataType = Record<string, any>;

// function sorterNumber(a: number, b: number): number {
//     return a - b;
// }

// function sorterString(a: string, b: string): number {
//     return a === b ? 0 : a > b ? 1 : -1;
// }

export default HighscoreTable;