import { autorun, makeAutoObservable, reaction, runInAction, toJS } from "mobx";
import GraphQL from "services/api/GraphQL";
import { CategoriesDocument, CategoriesQuery, GetStoreItemsDocument, GetStoreItemsQuery, StoreCategory, StoreItem } from "services/api/GraphqlTypes";
import ReactiveStoreItemsStore from "./ReactiveStoreItemsStore";

type SortBy = "category" | "price-asc" | "price-desc";

export default class StoreItemsStore {
    public loadingItems: boolean = false;
    public loadingCategories: boolean = false;
    public sortBy: SortBy = "category";
    public activeCategories: number[] = [];
    public categories: Pick<StoreCategory, "id" | "name" | "image">[] = [];
    public storeItems: StoreItem[] = [];

    public get storeItemsFilteredAndSorted(): StoreItem[] {
        // Observe
        toJS(this.activeCategories);
        toJS(this.sortBy);
        const items = toJS(this.storeItems);

        return items.filter(x =>
                !this.activeCategories?.length || x.categoryIds.some(c => this.activeCategories.contains(c)))
            .sort((a, b) => {
                switch (this.sortBy) {
                    case "category": {
                        return a.categoryIds.sort().join().localeCompare(b.categoryIds.sort().join());
                    }
                    case "price-asc": {
                        return a.price - b.price;
                    }
                    case "price-desc": {
                        return b.price - a.price;
                    }
                    default: return 0;
                }
            });
    }

    private reactiveStore: ReactiveStoreItemsStore | undefined;

    private rootDisposer: Disposer;
    private innerDisposer: Disposer | undefined;

    constructor() {
        makeAutoObservable(this);

        setTimeout(() => {
            this.refreshItems();
            this.refreshCategories();
        });

        this.rootDisposer = reaction(r => this.storeItems, (v, p, r) => {
            if (this.reactiveStore) {
                this.innerDisposer && this.innerDisposer();
                const old = this.reactiveStore;
                setTimeout(() => old.dispose());
            }

            this.reactiveStore = new ReactiveStoreItemsStore(v);
            this.innerDisposer = autorun(() => {
                const reactiveItems = toJS(this.reactiveStore?.storeItems ?? []);
                runInAction(() => {
                    for (let i = 0; i < this.storeItems.length; i++) {
                        this.storeItems[i] = reactiveItems.find(x => x.id === this.storeItems[i].id) || this.storeItems[i];
                    }
                });
            })
        });
    }

    public async refreshItems(): Promise<void> {
        runInAction(() => this.loadingItems = true);
        try {
            const result = await GraphQL.query<GetStoreItemsQuery>(GetStoreItemsDocument, { storeItems: [] });
            runInAction(() => {
                this.storeItems = (result?.data?.storeItems || []) as StoreItem[];
            });
        } catch (e) {
            console.error(e);
        }
        runInAction(() => this.loadingItems = false);
    }

    public async refreshCategories(): Promise<void> {
        runInAction(() => this.loadingCategories = true);
        try {
            const result = await GraphQL.query<CategoriesQuery>(CategoriesDocument, { categories: [] });
            runInAction(() => {
                this.categories = result.data?.categories || [];
                this.activeCategories = this.activeCategories.filter(x => this.categories.containsWhere(c => c.id === x));
            });
        } catch (e) {
            console.error(e);
        }
        runInAction(() => this.loadingCategories = false);
    }

    public dispose(): void {
        if (this.rootDisposer) {
            this.rootDisposer();
        }
        if (this.innerDisposer) {
            this.innerDisposer();
            this.innerDisposer = undefined;
        }
    }
}