import { Col, Row, Button, Alert } from "antd";
import Title from "antd/lib/typography/Title";
import React, { CSSProperties } from "react";
import { WindowsFilled, AppleFilled } from "@ant-design/icons";
import Link from "antd/lib/typography/Link";
import Text from "antd/lib/typography/Text";
import launcherImageMacOS from "../assets/images/launcher-macos.png";
import launcherImageWindows from "../assets/images/launcher-windows.jpg";
import Countdown from "react-countdown";
import SpaceChar from "components/Helpers/SpaceChar";
import releaseDate from "releaseDate";
import classNames from "classnames";
import { useSetBackgroundImage } from "services/hooks/useBackgroundImage";

const downloadButtonStyles: CSSProperties = {
    fontSize: 24,
    width: 240,
    height: 70,
    marginTop: 16
};

const downloadLinkMacOS = encodeURI("https://cdn.enchantps.com/launcher/update/enchant-launcher-1.1.1-universal.dmg");
const downloadLinkWindows = encodeURI("https://cdn.enchantps.com/launcher/update/enchant-launcher Setup 1.1.1.exe");
const downloadLinkJar = encodeURI("https://api.enchantps.com/api/v2/client/direct/live");

const Download: React.FC = () => {
    useSetBackgroundImage("/images/bg-main-fading.png");

    const readyToDownload = releaseDate.getTime() <= Date.now();

    return (
        <>
            <Row
                align="middle"
                style={{ marginTop: 24, textAlign: "center" }}
            >
                <Col span={24} md={12} style={{ marginBottom: 16 }}>
                    <Title level={1} style={{ color: "#222" }}>Windows</Title>
                    <div
                        className={classNames("launcher download-launcher", { "hoverable": readyToDownload })}
                        onClick={() => {
                            if (!readyToDownload) {
                                return;
                            }
                            window.location.href = downloadLinkWindows;
                        }}
                    >
                        <img
                            src={launcherImageWindows}
                            alt="Enchant Launcher Windows"
                            style={{ maxWidth: "100%" }}
                        />
                    </div>

                    <Countdown
                        date={releaseDate}
                        zeroPadDays={0}
                        zeroPadTime={2}
                        renderer={({ completed, days, formatted }) => {
                            if (completed) {
                                return (
                                    <Button
                                        type="primary"
                                        size="large"
                                        style={downloadButtonStyles}
                                        icon={<WindowsFilled />}
                                        className="download-launcher"
                                        onClick={() => {
                                            window.location.href = downloadLinkWindows;
                                        }}
                                    >
                                        DOWNLOAD
                                    </Button>
                                );
                            } else {
                                return (
                                    <Button
                                        disabled
                                        type="primary"
                                        size="large"
                                        style={{
                                            ...downloadButtonStyles,
                                            height: 100,
                                            backgroundColor: "#663399",
                                            borderColor: "#663399"
                                        }}
                                    >
                                        <div><WindowsFilled /> DOWNLOAD IN</div>
                                        <div style={{ color: "#fff" }}>
                                            {days && <>{days} Days<SpaceChar /></>}
                                            {formatted.hours}:{formatted.minutes}:{formatted.seconds}
                                        </div>
                                    </Button>
                                );
                            }
                        }}
                    />
                </Col>

                <Col span={24} md={12} style={{ marginBottom: 16 }}>
                    <Title level={1} style={{ color: "#222" }}>MacOS</Title>
                    <div
                        className={classNames("launcher macos download-launcher", { "hoverable": readyToDownload })}
                        onClick={() => {
                            if (!readyToDownload) {
                                return;
                            }
                            window.location.href = downloadLinkMacOS;
                        }}
                    >
                        <img
                            src={launcherImageMacOS}
                            alt="Enchant Launcher MacOS"
                            style={{ maxWidth: "100%" }}
                        />
                    </div>

                    <Countdown
                        date={releaseDate}
                        zeroPadDays={0}
                        zeroPadTime={2}
                        renderer={({ completed, days, formatted }) => {
                            if (completed) {
                                return (
                                    <Button
                                        type="primary"
                                        size="large"
                                        style={downloadButtonStyles}
                                        icon={<AppleFilled />}
                                        className="download-launcher"
                                        onClick={() => {
                                            window.location.href = downloadLinkMacOS;
                                        }}
                                    >
                                        DOWNLOAD
                                    </Button>
                                );
                            } else {
                                return (
                                    <Button
                                        disabled
                                        type="primary"
                                        size="large"
                                        style={{
                                            ...downloadButtonStyles,
                                            height: 100,
                                            backgroundColor: "#663399",
                                            borderColor: "#663399"
                                        }}
                                    >
                                        <div><AppleFilled /> DOWNLOAD IN</div>
                                        <div style={{ color: "#fff" }}>
                                            {days && <>{days} Days<SpaceChar /></>}
                                            {formatted.hours}:{formatted.minutes}:{formatted.seconds}
                                        </div>
                                    </Button>
                                );
                            }
                        }}
                    />
                </Col>
            </Row>
            <div style={{ flex: 1 }} />

            <Countdown
                date={releaseDate}
                zeroPadDays={0}
                zeroPadTime={2}
                renderer={({ completed, days, formatted }) => {
                    if (completed) {
                        return (
                            <Row justify="center" style={{ textAlign: 'center' }}>
                                <Alert
                                    type="info"
                                    style={{ width: "100%" }}
                                    message={<>The above applications serve as a HUB for the game, ensure the game is always up-to-date when you want to play and give you updates about our features and status.
                                        If you're having issues with the application or for any reason you prefer to manually be downlowding the game, you can find a direct link to our <Text strong>jar </Text>
                                        <Link className="download" href={downloadLinkJar}>here</Link>.</>}
                                />
                            </Row>
                        );
                    } else {
                        return null;
                    }
                }}
            />

        </>
    )
}

export default Download;