class ElementUtils {
    
    public createElement<K extends keyof HTMLElementTagNameMap>(tagName: K, attributes?: Partial<HTMLElementTagNameMap[K]>): HTMLElementTagNameMap[K] {
        const el = document.createElement(tagName);
        if (attributes) {
            Object.keys(attributes).forEach(value => {
                const key = value as keyof HTMLElementTagNameMap[K];
                (el[key] as any) = attributes[key];
            });
        }
        return el;
    }
}

export default new ElementUtils();