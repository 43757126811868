import { Col, PageHeader, Row } from 'antd';
import React from 'react';

interface Props {
    pageHeader?: string;
}

const ContentPageLayout: React.FC<Props> = ({ children, pageHeader, ...other }) => {
    return (
        <Row justify="center">
            <Col className="content-page">
                {pageHeader ? <PageHeader title={pageHeader} ghost={false}>{children}</PageHeader> :
                    children}
            </Col>
        </Row>
    );
}

export default ContentPageLayout;