import { Button } from "antd";
import moment from "moment";
import { useMemo } from "react";

interface IVoteButtonProps {
    href: string;
    disabled?: boolean;
    disabledUntil?: string;
    style?: React.CSSProperties;
}

const VoteButton: React.FC<IVoteButtonProps> = ({ href, style, children, ...props }) => {
    let disabledUntil: string | null = null;
    if (props.disabledUntil) {
        try {
            const until = moment.utc(props.disabledUntil, 'YYYY-MM-DD hh:mm:ss');
            if (until.diff(moment()) > 0) {
                disabledUntil = until.fromNow();
            }
        } catch { }
    }

    const disabled = props.disabled || !!disabledUntil;

    const button = useMemo(() => (
        <Button
            style={style}
            type="primary"
            disabled={disabled}
            block
        >{disabledUntil || children}</Button>
    ), [style, disabled, disabledUntil]);

    return disabled ? button : (
        <a
            target="_blank"
            rel="noreferrer"
            href={href}
        >
            {button}
        </a>
    );
}

export default VoteButton;