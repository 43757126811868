class StringUtils {
    public ucWords(string: string) {
        return string.toLowerCase().split(" ").map(x => `${x.slice(0, 1).toUpperCase()}${x.slice(1)}`).join(" ");
    }

    public camelCaseToWords(string: string): string {
        "abcAbcAbc => Abc Abc Abc"

        let final = "";

        let upperCaseNext = true;
        for (const c of string) {
            if (upperCaseNext) {
                final += c.toUpperCase();
                upperCaseNext = false;
            } else if (c.toUpperCase() === c) {
                final += ` ${c}`;
            } else {
                final += c;
            }
        }

        return final;
    }

    public sanitize(string: string): string {
        return string.toLowerCase().replaceAll(" ", "-");
    }
}

export default new StringUtils();