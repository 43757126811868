import React, { useContext, useEffect, useMemo, useState } from "react";

interface IBackgroundImageContext {
    setBackground: (src: string) => void;
}

const Context = React.createContext<IBackgroundImageContext>({
    setBackground: () => {}
});

export function useBackgroundImage() {
    const [src, setSrc] = useState<string | undefined>();
    const ctx = useMemo<IBackgroundImageContext>(() => ({
        setBackground: setSrc
    }), []);
    return { src, ContextProvider: Context.Provider, context: ctx }
}

export function useSetBackgroundImage(src: string) {
    const ctx = useContext(Context);
    useEffect(() => {
        ctx?.setBackground && ctx.setBackground(src);
    }, [ctx]);
}