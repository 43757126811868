import { Col, Form, Menu, Row, Select, Spin } from "antd";
import Search from "antd/lib/input/Search";
import HighscoreMiscTableRoute from "components/Highscores/HighscoreMiscTableRoute";
import HighscoreSkillsTableRoute from "components/Highscores/HighscoreSkillsTableRoute";
import ContentPageLayout from "components/Layouts/ContentPageLayout";
import { useEffect, useMemo } from "react";
import { Link, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useGetHighscoresBaseQuery } from "services/api/GraphqlTypes";
import useSearchQuery from "services/hooks/useSearchQuery";
import StringUtils from "services/utils/StringUtils";

const makeCatPath = (curUrl: string, name: string) => `${curUrl}/c/${name}`;
const makeSkillPath = (curUrl: string, name: string) => `${curUrl}/s/${name}`;

const withQuery = (path: string, query: URLSearchParams) => `${path}${query.toString() ? `?${query.toString()}` : ""}`;

const Highscores: React.FC = () => {
    const { data, loading } = useGetHighscoresBaseQuery();

    const gameModes = data?.gameModes || [];
    const gameSkills = data?.gameSkills || [];
    const miscCategories = data?.highscoreMiscCategories || [];

    const query = useSearchQuery();
    const { path, url } = useRouteMatch();
    const history = useHistory();

    const routesToMatch = useMemo(() => {
        return miscCategories.map(x => makeCatPath(url, x.name))
            .concat(
                gameSkills.map(x => makeSkillPath(url, x.name))
            );
    }, [data]);

    const matchedPath = useRouteMatch(routesToMatch)?.path;

    useEffect(() => {
        if (loading) {
            return;
        }
        const path = history.location.pathname;
        if (path.indexOf("/c/") < 0 && path.indexOf("/s/") < 0) {
            history.push(`${url}/c/${miscCategories[0]?.name}`);
        }
    }, [loading, data, history, url]);

    return (
        <ContentPageLayout
            pageHeader="Highscores"
        >
            <Row justify="end">
                <Col>
                    <Search
                        placeholder="Search username..."
                        onSearch={value => {
                            const newQuery = new URLSearchParams(query);
                            if (value) {
                                newQuery.set("search", value);
                            } else {
                                newQuery.delete("search");
                            }
                            history.push(`${history.location.pathname}?${newQuery.toString()}`);
                        }}
                        allowClear
                        enterButton
                    />
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={24} md={5}>
                    <Spin spinning={loading}>
                        <Form layout="vertical">
                            <Form.Item
                                label="Game Mode"
                            >
                                <Select
                                    value={StringUtils.ucWords(query.get("mode") || "normal")}
                                    style={{ width: "100%" }}
                                    onChange={value => {
                                        const newQuery = new URLSearchParams(query);
                                        newQuery.set("mode", value);
                                        history.push(`${history.location.pathname}?${newQuery.toString()}`);
                                    }}
                                >
                                    {gameModes.map(x => (
                                        <Select.Option
                                            key={x.id}
                                            value={x.name}
                                        >{StringUtils.ucWords(x.name.replaceAll("_", " "))}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form>

                        <Menu theme="light" mode="inline" selectedKeys={matchedPath ? [matchedPath] : []}>
                            <Menu.ItemGroup title="Categories">
                                {miscCategories.map(x => {
                                    return (
                                        <Menu.Item key={makeCatPath(url, x.name)}>
                                            <Link to={withQuery(makeCatPath(url, x.name), query)}>
                                                {StringUtils.ucWords(x.name)}
                                            </Link>
                                        </Menu.Item>
                                    );
                                })}
                            </Menu.ItemGroup>

                            <Menu.ItemGroup title="Skills">
                                {gameSkills.map(x => (
                                    <Menu.Item key={makeSkillPath(url, x.name)}>
                                        <Link to={withQuery(makeSkillPath(url, x.name), query)}>
                                            {StringUtils.ucWords(x.name)}
                                        </Link>
                                    </Menu.Item>
                                ))}
                            </Menu.ItemGroup>
                        </Menu>
                    </Spin>
                </Col>

                <Col span={24} md={19}>
                    {!loading && (
                        <Switch>
                            <Route path={`${path}/c/:name`} component={HighscoreMiscTableRoute} />
                            <Route path={`${path}/s/:name`} component={HighscoreSkillsTableRoute} />
                        </Switch>
                    )}
                </Col>
            </Row>
        </ContentPageLayout>
    );
}

export default Highscores;