import { useCallback } from "react";
import { useHistory } from "react-router-dom";

type QueryConsumer = (query: URLSearchParams) => void;

const UrlUtils = {
    usePushQuery: () => {
        const history = useHistory();
        return useCallback((consumer: QueryConsumer) => {
            const query = new URLSearchParams(history.location.search);
            consumer(query);
            const queryStr = query.toString();
            if (queryStr) {
                history.push(`${history.location.pathname}?${queryStr}`);
            } else {
                history.push(`${history.location.pathname}`);
            }
        }, [history]);
    }
}

export default UrlUtils;