import { Avatar, Button, Input, InputNumber, List, Tooltip } from "antd";
import GoldIcon from "components/Common/GoldIcon";
import BluredOverlay from "components/Helpers/BlurOverlay";
import React, { useEffect, useState } from "react";
import { isSoldOut } from "services/helpers/storeItemHelpers";
import useCountdown from "services/hooks/useCountdown";
import { CartItem } from "../../../services/api/GraphqlTypes";
import resolvePublicUrl from "../../../services/helpers/resolvePublicUrl";
import { useUpdateCartItem } from "../../../services/hooks/useShoppingCart";
import MathUtils from "../../../services/utils/MathUtils";

interface Props {
    item: CartItem;
    disabled?: boolean;
}

const ShoppingCartItem: React.FC<Props> = ({ item, disabled: disabledProp }) => {
    const [qty, setQty] = useState(item.quantity);
    const [update, { loading }] = useUpdateCartItem({
        storeItemId: item.storeItem.id,
        quantity: qty
    });
    const [remove, { loading: removing }] = useUpdateCartItem({
        storeItemId: item.storeItem.id,
        quantity: 0
    });

    useEffect(() => {
        setQty(item.quantity);
    }, [item]);

    const { countdown, countdownEnded } = useCountdown(item.storeItem.expiresAt);
    const disabled = loading || removing || disabledProp;

    return (
        <List.Item
            className="shopping-cart-item"
            actions={[
                <Input.Group compact>
                    <InputNumber
                        min={1}
                        max={1000}
                        precision={0}
                        value={qty}
                        onChange={setQty}
                        disabled={isSoldOut(item) || countdownEnded}
                    />

                    <Button
                        type="primary"
                        loading={loading}
                        disabled={disabled || item.quantity === qty || isSoldOut(item) || countdownEnded}
                        onClick={() => item.quantity !== qty && update()}
                    >
                        Update
                    </Button>

                    <Button
                        type="link"
                        disabled={disabled || item.quantity === qty || countdownEnded}
                        onClick={() => setQty(item.quantity)}
                    >Reset</Button>

                    <Button
                        danger
                        type="link"
                        loading={removing}
                        disabled={disabled}
                        onClick={() => remove()}
                    >Remove</Button>
                </Input.Group>,
            ]}
        >
            <BluredOverlay
                text="EXPIRED"
                enabled={!!item.storeItem.expiresAt && countdownEnded}
            >
                <List.Item.Meta
                    avatar={
                        <Avatar src={resolvePublicUrl(item.storeItem.image)} shape="square" />
                    }
                    title={<GoldIcon prefix={`${item.storeItem.name}`} suffix={MathUtils.roundToString(item.storeItem.price * item.quantity)} />}
                    description={(
                        <Tooltip title={item.storeItem.description}>
                            <span className="ellipse">{item.storeItem.description}</span>
                        </Tooltip>
                    )}
                />
            </BluredOverlay>

            {item.storeItem.useStock && (
                <span className="remaining">
                    {(item.storeItem.stock || 0) > 0
                        ? <>Only <div className="amount zoom-anim">{item.storeItem.stock}</div> left!</>
                        : "Sold out!"}
                </span>
            )}

            {item.storeItem.expiresAt && !countdownEnded && (
                <span className="expires-at">
                    {countdownEnded ? "Expired" : `Expires in ${countdown}`}
                </span>
            )}
        </List.Item>
    );
}

export default ShoppingCartItem;