import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: string;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: string;
};

export type CartItem = {
  __typename?: 'CartItem';
  storeItem: StoreItem;
  quantity: Scalars['Int'];
};

export type CartMutationResponse = {
  __typename?: 'CartMutationResponse';
  storeItemId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type CheckoutResponse = {
  __typename?: 'CheckoutResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  items?: Maybe<Array<CheckoutResponseItem>>;
  total?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
};

export type CheckoutResponseItem = {
  __typename?: 'CheckoutResponseItem';
  name: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type CursorPaginator = {
  __typename?: 'CursorPaginator';
  perPage: Scalars['Int'];
  nextPageToken?: Maybe<Scalars['String']>;
  prevPageToken?: Maybe<Scalars['String']>;
};



export type GameMode = {
  __typename?: 'GameMode';
  id: Scalars['Int'];
  name: Scalars['String'];
  image?: Maybe<Scalars['String']>;
};

export type GameSkill = {
  __typename?: 'GameSkill';
  id: Scalars['Int'];
  name: Scalars['String'];
  maxExperience: Scalars['Float'];
  image?: Maybe<Scalars['String']>;
};

export type HighscoreMisc = {
  __typename?: 'HighscoreMisc';
  rank: Scalars['Int'];
  username: Scalars['String'];
  gameRank: Scalars['String'];
  gameMode: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['Int'];
};

export type HighscoreMiscCategory = {
  __typename?: 'HighscoreMiscCategory';
  id: Scalars['Int'];
  name: Scalars['String'];
  image?: Maybe<Scalars['String']>;
};

export type HighscoreMiscPaginated = {
  __typename?: 'HighscoreMiscPaginated';
  data: Array<HighscoreMisc>;
  paginatorInfo: CursorPaginator;
};

export type HighscoreSkill = {
  __typename?: 'HighscoreSkill';
  rank: Scalars['Int'];
  username: Scalars['String'];
  gameRank: Scalars['String'];
  gameMode: Scalars['String'];
  skill: Scalars['String'];
  level: Scalars['Int'];
  experience: Scalars['String'];
};

export type HighscoreSkillPaginated = {
  __typename?: 'HighscoreSkillPaginated';
  data: Array<HighscoreSkill>;
  paginatorInfo: CursorPaginator;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  user: User;
  token: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  login: LoginResponse;
  logout?: Maybe<Scalars['Boolean']>;
  updateCartItem: CartMutationResponse;
  emptyCart?: Maybe<Scalars['Boolean']>;
  checkout?: Maybe<CheckoutResponse>;
};


export type MutationLoginArgs = {
  name: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateCartItemArgs = {
  storeItemId: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Minimum. */
  Min = 'MIN',
  /** Maximum. */
  Max = 'MAX',
  /** Sum. */
  Sum = 'SUM',
  /** Amount of items. */
  Count = 'COUNT'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<User>;
  cart: Array<CartItem>;
  goldBundles: Array<StoreGoldBundle>;
  categories: Array<StoreCategory>;
  storeItems: Array<StoreItem>;
  gameModes: Array<GameMode>;
  gameSkills: Array<GameSkill>;
  highscoreMiscCategories: Array<HighscoreMiscCategory>;
  highscoreSkill: HighscoreSkillPaginated;
  highscoreMisc: HighscoreMiscPaginated;
  voteProviders: Array<VoteProvider>;
  topMonthlyVoters: Array<TopVoter>;
};


export type QueryHighscoreSkillArgs = {
  perPage: Scalars['Int'];
  pageToken?: Maybe<Scalars['String']>;
  game_mode: Scalars['String'];
  name: Scalars['String'];
  search?: Maybe<Scalars['String']>;
};


export type QueryHighscoreMiscArgs = {
  perPage: Scalars['Int'];
  pageToken?: Maybe<Scalars['String']>;
  game_mode: Scalars['String'];
  name: Scalars['String'];
  search?: Maybe<Scalars['String']>;
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type StoreCategory = {
  __typename?: 'StoreCategory';
  id: Scalars['Int'];
  name: Scalars['String'];
  image: Scalars['String'];
};

export type StoreGoldBundle = {
  __typename?: 'StoreGoldBundle';
  id: Scalars['Int'];
  currencySymbol: Scalars['String'];
  price: Scalars['Float'];
  baseAmount: Scalars['Int'];
  bonusAmount: Scalars['Int'];
  image: Scalars['String'];
};

export type StoreItem = {
  __typename?: 'StoreItem';
  id: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  descriptionHtml?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  compareAtPrice: Scalars['Int'];
  image: Scalars['String'];
  categoryIds: Array<Scalars['Int']>;
  useStock: Scalars['Boolean'];
  stock: Scalars['Int'];
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type StoreItemStockUpdate = {
  __typename?: 'StoreItemStockUpdate';
  id: Scalars['Int'];
  stock: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  storeItemStockUpdate?: Maybe<Array<StoreItemStockUpdate>>;
  voteProviderUpdate?: Maybe<VoteProvider>;
};

export type TopVoter = {
  __typename?: 'TopVoter';
  name: Scalars['String'];
  rank: Scalars['String'];
  votes: Scalars['Int'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  name: Scalars['String'];
  gold: Scalars['Int'];
  goldSpent: Scalars['Int'];
  nextRankGoldRequirement: Scalars['Int'];
  rank: Scalars['String'];
  closeRanks: Array<Scalars['String']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type VoteProvider = {
  __typename?: 'VoteProvider';
  name: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  voteUrl?: Maybe<Scalars['String']>;
  disabledUntil?: Maybe<Scalars['DateTime']>;
};

export type CheckoutMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckoutMutation = (
  { __typename?: 'Mutation' }
  & { checkout?: Maybe<(
    { __typename?: 'CheckoutResponse' }
    & Pick<CheckoutResponse, 'success' | 'error' | 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'CheckoutResponseItem' }
      & Pick<CheckoutResponseItem, 'name' | 'price' | 'quantity'>
    )>>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'gold' | 'goldSpent' | 'nextRankGoldRequirement' | 'rank' | 'closeRanks'>
    )> }
  )> }
);

export type EmptyCartMutationVariables = Exact<{ [key: string]: never; }>;


export type EmptyCartMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'emptyCart'>
);

export type LoginMutationVariables = Exact<{
  name: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'gold' | 'goldSpent' | 'nextRankGoldRequirement' | 'rank' | 'closeRanks'>
    ) }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type UpdateCartItemMutationVariables = Exact<{
  storeItemId: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
}>;


export type UpdateCartItemMutation = (
  { __typename?: 'Mutation' }
  & { updateCartItem: (
    { __typename?: 'CartMutationResponse' }
    & Pick<CartMutationResponse, 'storeItemId' | 'quantity'>
  ) }
);

export type CartQueryVariables = Exact<{ [key: string]: never; }>;


export type CartQuery = (
  { __typename?: 'Query' }
  & { cart: Array<(
    { __typename?: 'CartItem' }
    & Pick<CartItem, 'quantity'>
    & { storeItem: (
      { __typename?: 'StoreItem' }
      & Pick<StoreItem, 'id' | 'name' | 'description' | 'price' | 'compareAtPrice' | 'image' | 'useStock' | 'stock'>
    ) }
  )> }
);

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'StoreCategory' }
    & Pick<StoreCategory, 'id' | 'name' | 'image'>
  )> }
);

export type GetHighscoreMiscQueryVariables = Exact<{
  perPage: Scalars['Int'];
  pageToken?: Maybe<Scalars['String']>;
  gameMode: Scalars['String'];
  name: Scalars['String'];
  search?: Maybe<Scalars['String']>;
}>;


export type GetHighscoreMiscQuery = (
  { __typename?: 'Query' }
  & { highscoreMisc: (
    { __typename?: 'HighscoreMiscPaginated' }
    & { paginatorInfo: (
      { __typename?: 'CursorPaginator' }
      & Pick<CursorPaginator, 'perPage' | 'nextPageToken' | 'prevPageToken'>
    ), data: Array<(
      { __typename?: 'HighscoreMisc' }
      & Pick<HighscoreMisc, 'rank' | 'username' | 'gameRank' | 'gameMode' | 'name' | 'value'>
    )> }
  ) }
);

export type GetHighscoreSkillQueryVariables = Exact<{
  perPage: Scalars['Int'];
  pageToken?: Maybe<Scalars['String']>;
  gameMode: Scalars['String'];
  name: Scalars['String'];
  search?: Maybe<Scalars['String']>;
}>;


export type GetHighscoreSkillQuery = (
  { __typename?: 'Query' }
  & { highscoreSkill: (
    { __typename?: 'HighscoreSkillPaginated' }
    & { paginatorInfo: (
      { __typename?: 'CursorPaginator' }
      & Pick<CursorPaginator, 'perPage' | 'nextPageToken' | 'prevPageToken'>
    ), data: Array<(
      { __typename?: 'HighscoreSkill' }
      & Pick<HighscoreSkill, 'rank' | 'username' | 'gameRank' | 'gameMode' | 'skill' | 'level' | 'experience'>
    )> }
  ) }
);

export type GetHighscoresBaseQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHighscoresBaseQuery = (
  { __typename?: 'Query' }
  & { gameModes: Array<(
    { __typename?: 'GameMode' }
    & Pick<GameMode, 'id' | 'name' | 'image'>
  )>, gameSkills: Array<(
    { __typename?: 'GameSkill' }
    & Pick<GameSkill, 'id' | 'name' | 'maxExperience' | 'image'>
  )>, highscoreMiscCategories: Array<(
    { __typename?: 'HighscoreMiscCategory' }
    & Pick<HighscoreMiscCategory, 'id' | 'name' | 'image'>
  )> }
);

export type GetStoreGoldBundlesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoreGoldBundlesQuery = (
  { __typename?: 'Query' }
  & { goldBundles: Array<(
    { __typename?: 'StoreGoldBundle' }
    & Pick<StoreGoldBundle, 'id' | 'price' | 'currencySymbol' | 'baseAmount' | 'bonusAmount' | 'image'>
  )> }
);

export type GetStoreItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoreItemsQuery = (
  { __typename?: 'Query' }
  & { storeItems: Array<(
    { __typename?: 'StoreItem' }
    & Pick<StoreItem, 'id' | 'name' | 'description' | 'descriptionHtml' | 'price' | 'compareAtPrice' | 'image' | 'categoryIds' | 'useStock' | 'stock' | 'expiresAt'>
  )> }
);

export type GetVotePageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVotePageQuery = (
  { __typename?: 'Query' }
  & { voteProviders: Array<(
    { __typename?: 'VoteProvider' }
    & Pick<VoteProvider, 'name' | 'image' | 'voteUrl' | 'disabledUntil'>
  )> }
);

export type GetTopMonthlyVotersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTopMonthlyVotersQuery = (
  { __typename?: 'Query' }
  & { topMonthlyVoters: Array<(
    { __typename?: 'TopVoter' }
    & Pick<TopVoter, 'name' | 'rank' | 'votes'>
  )> }
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'gold' | 'goldSpent' | 'nextRankGoldRequirement' | 'rank' | 'closeRanks'>
  )> }
);

export type StoreItemStockUpdateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type StoreItemStockUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { storeItemStockUpdate?: Maybe<Array<(
    { __typename?: 'StoreItemStockUpdate' }
    & Pick<StoreItemStockUpdate, 'id' | 'stock'>
  )>> }
);

export type VoteProviderUpdateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type VoteProviderUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { voteProviderUpdate?: Maybe<(
    { __typename?: 'VoteProvider' }
    & Pick<VoteProvider, 'name' | 'image' | 'voteUrl' | 'disabledUntil'>
  )> }
);


export const CheckoutDocument = gql`
    mutation Checkout {
  checkout {
    success
    error
    total
    items {
      name
      price
      quantity
    }
    user {
      id
      name
      gold
      goldSpent
      nextRankGoldRequirement
      rank
      closeRanks
    }
  }
}
    `;
export type CheckoutMutationFn = Apollo.MutationFunction<CheckoutMutation, CheckoutMutationVariables>;

/**
 * __useCheckoutMutation__
 *
 * To run a mutation, you first call `useCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutMutation, { data, loading, error }] = useCheckoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckoutMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutMutation, CheckoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckoutMutation, CheckoutMutationVariables>(CheckoutDocument, options);
      }
export type CheckoutMutationHookResult = ReturnType<typeof useCheckoutMutation>;
export type CheckoutMutationResult = Apollo.MutationResult<CheckoutMutation>;
export type CheckoutMutationOptions = Apollo.BaseMutationOptions<CheckoutMutation, CheckoutMutationVariables>;
export const EmptyCartDocument = gql`
    mutation EmptyCart {
  emptyCart
}
    `;
export type EmptyCartMutationFn = Apollo.MutationFunction<EmptyCartMutation, EmptyCartMutationVariables>;

/**
 * __useEmptyCartMutation__
 *
 * To run a mutation, you first call `useEmptyCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyCartMutation, { data, loading, error }] = useEmptyCartMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptyCartMutation(baseOptions?: Apollo.MutationHookOptions<EmptyCartMutation, EmptyCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmptyCartMutation, EmptyCartMutationVariables>(EmptyCartDocument, options);
      }
export type EmptyCartMutationHookResult = ReturnType<typeof useEmptyCartMutation>;
export type EmptyCartMutationResult = Apollo.MutationResult<EmptyCartMutation>;
export type EmptyCartMutationOptions = Apollo.BaseMutationOptions<EmptyCartMutation, EmptyCartMutationVariables>;
export const LoginDocument = gql`
    mutation Login($name: String!, $password: String!) {
  login(name: $name, password: $password) {
    user {
      id
      name
      gold
      goldSpent
      nextRankGoldRequirement
      rank
      closeRanks
    }
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      name: // value for 'name'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const UpdateCartItemDocument = gql`
    mutation UpdateCartItem($storeItemId: Int!, $quantity: Int) {
  updateCartItem(storeItemId: $storeItemId, quantity: $quantity) {
    storeItemId
    quantity
  }
}
    `;
export type UpdateCartItemMutationFn = Apollo.MutationFunction<UpdateCartItemMutation, UpdateCartItemMutationVariables>;

/**
 * __useUpdateCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemMutation, { data, loading, error }] = useUpdateCartItemMutation({
 *   variables: {
 *      storeItemId: // value for 'storeItemId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateCartItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCartItemMutation, UpdateCartItemMutationVariables>(UpdateCartItemDocument, options);
      }
export type UpdateCartItemMutationHookResult = ReturnType<typeof useUpdateCartItemMutation>;
export type UpdateCartItemMutationResult = Apollo.MutationResult<UpdateCartItemMutation>;
export type UpdateCartItemMutationOptions = Apollo.BaseMutationOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>;
export const CartDocument = gql`
    query Cart {
  cart {
    quantity
    storeItem {
      id
      name
      description
      price
      compareAtPrice
      image
      useStock
      stock
    }
  }
}
    `;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartQuery(baseOptions?: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
      }
export function useCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(CartDocument, options);
        }
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const CategoriesDocument = gql`
    query Categories {
  categories {
    id
    name
    image
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const GetHighscoreMiscDocument = gql`
    query GetHighscoreMisc($perPage: Int!, $pageToken: String, $gameMode: String!, $name: String!, $search: String) {
  highscoreMisc(
    perPage: $perPage
    pageToken: $pageToken
    game_mode: $gameMode
    name: $name
    search: $search
  ) {
    paginatorInfo {
      perPage
      nextPageToken
      prevPageToken
    }
    data {
      rank
      username
      gameRank
      gameMode
      name
      value
    }
  }
}
    `;

/**
 * __useGetHighscoreMiscQuery__
 *
 * To run a query within a React component, call `useGetHighscoreMiscQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHighscoreMiscQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHighscoreMiscQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      pageToken: // value for 'pageToken'
 *      gameMode: // value for 'gameMode'
 *      name: // value for 'name'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetHighscoreMiscQuery(baseOptions: Apollo.QueryHookOptions<GetHighscoreMiscQuery, GetHighscoreMiscQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHighscoreMiscQuery, GetHighscoreMiscQueryVariables>(GetHighscoreMiscDocument, options);
      }
export function useGetHighscoreMiscLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHighscoreMiscQuery, GetHighscoreMiscQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHighscoreMiscQuery, GetHighscoreMiscQueryVariables>(GetHighscoreMiscDocument, options);
        }
export type GetHighscoreMiscQueryHookResult = ReturnType<typeof useGetHighscoreMiscQuery>;
export type GetHighscoreMiscLazyQueryHookResult = ReturnType<typeof useGetHighscoreMiscLazyQuery>;
export type GetHighscoreMiscQueryResult = Apollo.QueryResult<GetHighscoreMiscQuery, GetHighscoreMiscQueryVariables>;
export const GetHighscoreSkillDocument = gql`
    query GetHighscoreSkill($perPage: Int!, $pageToken: String, $gameMode: String!, $name: String!, $search: String) {
  highscoreSkill(
    perPage: $perPage
    pageToken: $pageToken
    game_mode: $gameMode
    name: $name
    search: $search
  ) {
    paginatorInfo {
      perPage
      nextPageToken
      prevPageToken
    }
    data {
      rank
      username
      gameRank
      gameMode
      skill
      level
      experience
    }
  }
}
    `;

/**
 * __useGetHighscoreSkillQuery__
 *
 * To run a query within a React component, call `useGetHighscoreSkillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHighscoreSkillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHighscoreSkillQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      pageToken: // value for 'pageToken'
 *      gameMode: // value for 'gameMode'
 *      name: // value for 'name'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetHighscoreSkillQuery(baseOptions: Apollo.QueryHookOptions<GetHighscoreSkillQuery, GetHighscoreSkillQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHighscoreSkillQuery, GetHighscoreSkillQueryVariables>(GetHighscoreSkillDocument, options);
      }
export function useGetHighscoreSkillLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHighscoreSkillQuery, GetHighscoreSkillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHighscoreSkillQuery, GetHighscoreSkillQueryVariables>(GetHighscoreSkillDocument, options);
        }
export type GetHighscoreSkillQueryHookResult = ReturnType<typeof useGetHighscoreSkillQuery>;
export type GetHighscoreSkillLazyQueryHookResult = ReturnType<typeof useGetHighscoreSkillLazyQuery>;
export type GetHighscoreSkillQueryResult = Apollo.QueryResult<GetHighscoreSkillQuery, GetHighscoreSkillQueryVariables>;
export const GetHighscoresBaseDocument = gql`
    query GetHighscoresBase {
  gameModes {
    id
    name
    image
  }
  gameSkills {
    id
    name
    maxExperience
    image
  }
  highscoreMiscCategories {
    id
    name
    image
  }
}
    `;

/**
 * __useGetHighscoresBaseQuery__
 *
 * To run a query within a React component, call `useGetHighscoresBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHighscoresBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHighscoresBaseQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHighscoresBaseQuery(baseOptions?: Apollo.QueryHookOptions<GetHighscoresBaseQuery, GetHighscoresBaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHighscoresBaseQuery, GetHighscoresBaseQueryVariables>(GetHighscoresBaseDocument, options);
      }
export function useGetHighscoresBaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHighscoresBaseQuery, GetHighscoresBaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHighscoresBaseQuery, GetHighscoresBaseQueryVariables>(GetHighscoresBaseDocument, options);
        }
export type GetHighscoresBaseQueryHookResult = ReturnType<typeof useGetHighscoresBaseQuery>;
export type GetHighscoresBaseLazyQueryHookResult = ReturnType<typeof useGetHighscoresBaseLazyQuery>;
export type GetHighscoresBaseQueryResult = Apollo.QueryResult<GetHighscoresBaseQuery, GetHighscoresBaseQueryVariables>;
export const GetStoreGoldBundlesDocument = gql`
    query GetStoreGoldBundles {
  goldBundles {
    id
    price
    currencySymbol
    baseAmount
    bonusAmount
    image
  }
}
    `;

/**
 * __useGetStoreGoldBundlesQuery__
 *
 * To run a query within a React component, call `useGetStoreGoldBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreGoldBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreGoldBundlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStoreGoldBundlesQuery(baseOptions?: Apollo.QueryHookOptions<GetStoreGoldBundlesQuery, GetStoreGoldBundlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreGoldBundlesQuery, GetStoreGoldBundlesQueryVariables>(GetStoreGoldBundlesDocument, options);
      }
export function useGetStoreGoldBundlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreGoldBundlesQuery, GetStoreGoldBundlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreGoldBundlesQuery, GetStoreGoldBundlesQueryVariables>(GetStoreGoldBundlesDocument, options);
        }
export type GetStoreGoldBundlesQueryHookResult = ReturnType<typeof useGetStoreGoldBundlesQuery>;
export type GetStoreGoldBundlesLazyQueryHookResult = ReturnType<typeof useGetStoreGoldBundlesLazyQuery>;
export type GetStoreGoldBundlesQueryResult = Apollo.QueryResult<GetStoreGoldBundlesQuery, GetStoreGoldBundlesQueryVariables>;
export const GetStoreItemsDocument = gql`
    query GetStoreItems {
  storeItems {
    id
    name
    description
    descriptionHtml
    price
    compareAtPrice
    image
    categoryIds
    useStock
    stock
    expiresAt
  }
}
    `;

/**
 * __useGetStoreItemsQuery__
 *
 * To run a query within a React component, call `useGetStoreItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStoreItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetStoreItemsQuery, GetStoreItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreItemsQuery, GetStoreItemsQueryVariables>(GetStoreItemsDocument, options);
      }
export function useGetStoreItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreItemsQuery, GetStoreItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreItemsQuery, GetStoreItemsQueryVariables>(GetStoreItemsDocument, options);
        }
export type GetStoreItemsQueryHookResult = ReturnType<typeof useGetStoreItemsQuery>;
export type GetStoreItemsLazyQueryHookResult = ReturnType<typeof useGetStoreItemsLazyQuery>;
export type GetStoreItemsQueryResult = Apollo.QueryResult<GetStoreItemsQuery, GetStoreItemsQueryVariables>;
export const GetVotePageDocument = gql`
    query GetVotePage {
  voteProviders {
    name
    image
    voteUrl
    disabledUntil
  }
}
    `;

/**
 * __useGetVotePageQuery__
 *
 * To run a query within a React component, call `useGetVotePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVotePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVotePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVotePageQuery(baseOptions?: Apollo.QueryHookOptions<GetVotePageQuery, GetVotePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVotePageQuery, GetVotePageQueryVariables>(GetVotePageDocument, options);
      }
export function useGetVotePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVotePageQuery, GetVotePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVotePageQuery, GetVotePageQueryVariables>(GetVotePageDocument, options);
        }
export type GetVotePageQueryHookResult = ReturnType<typeof useGetVotePageQuery>;
export type GetVotePageLazyQueryHookResult = ReturnType<typeof useGetVotePageLazyQuery>;
export type GetVotePageQueryResult = Apollo.QueryResult<GetVotePageQuery, GetVotePageQueryVariables>;
export const GetTopMonthlyVotersDocument = gql`
    query GetTopMonthlyVoters {
  topMonthlyVoters {
    name
    rank
    votes
  }
}
    `;

/**
 * __useGetTopMonthlyVotersQuery__
 *
 * To run a query within a React component, call `useGetTopMonthlyVotersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopMonthlyVotersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopMonthlyVotersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopMonthlyVotersQuery(baseOptions?: Apollo.QueryHookOptions<GetTopMonthlyVotersQuery, GetTopMonthlyVotersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopMonthlyVotersQuery, GetTopMonthlyVotersQueryVariables>(GetTopMonthlyVotersDocument, options);
      }
export function useGetTopMonthlyVotersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopMonthlyVotersQuery, GetTopMonthlyVotersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopMonthlyVotersQuery, GetTopMonthlyVotersQueryVariables>(GetTopMonthlyVotersDocument, options);
        }
export type GetTopMonthlyVotersQueryHookResult = ReturnType<typeof useGetTopMonthlyVotersQuery>;
export type GetTopMonthlyVotersLazyQueryHookResult = ReturnType<typeof useGetTopMonthlyVotersLazyQuery>;
export type GetTopMonthlyVotersQueryResult = Apollo.QueryResult<GetTopMonthlyVotersQuery, GetTopMonthlyVotersQueryVariables>;
export const UserDocument = gql`
    query User {
  me {
    id
    name
    gold
    goldSpent
    nextRankGoldRequirement
    rank
    closeRanks
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const StoreItemStockUpdateDocument = gql`
    subscription StoreItemStockUpdate {
  storeItemStockUpdate {
    id
    stock
  }
}
    `;

/**
 * __useStoreItemStockUpdateSubscription__
 *
 * To run a query within a React component, call `useStoreItemStockUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStoreItemStockUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreItemStockUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useStoreItemStockUpdateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<StoreItemStockUpdateSubscription, StoreItemStockUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<StoreItemStockUpdateSubscription, StoreItemStockUpdateSubscriptionVariables>(StoreItemStockUpdateDocument, options);
      }
export type StoreItemStockUpdateSubscriptionHookResult = ReturnType<typeof useStoreItemStockUpdateSubscription>;
export type StoreItemStockUpdateSubscriptionResult = Apollo.SubscriptionResult<StoreItemStockUpdateSubscription>;
export const VoteProviderUpdateDocument = gql`
    subscription VoteProviderUpdate {
  voteProviderUpdate {
    name
    image
    voteUrl
    disabledUntil
  }
}
    `;

/**
 * __useVoteProviderUpdateSubscription__
 *
 * To run a query within a React component, call `useVoteProviderUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVoteProviderUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVoteProviderUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useVoteProviderUpdateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<VoteProviderUpdateSubscription, VoteProviderUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<VoteProviderUpdateSubscription, VoteProviderUpdateSubscriptionVariables>(VoteProviderUpdateDocument, options);
      }
export type VoteProviderUpdateSubscriptionHookResult = ReturnType<typeof useVoteProviderUpdateSubscription>;
export type VoteProviderUpdateSubscriptionResult = Apollo.SubscriptionResult<VoteProviderUpdateSubscription>;