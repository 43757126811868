import TagManager from "react-gtm-module";
import AppStore from "services/stores/AppStore";

const GTM_ID = 'GTM-5Q7X86K';

export interface TransactionItem {
    name: string;
    sku?: string;
    category?: string;
    price?: number;
    currency?: string;
    quantity?: number;
}

export interface TransactionArgs {
    id: string;
    total?: number;
    affiliation?: string;
    tax?: number;
    shipping?: string;
    currency?: string;
    products?: TransactionItem[];
}

class Analytics {
    public initialize(): void {
        TagManager.initialize({
            gtmId: GTM_ID,
            dataLayer: {
                userId: AppStore.user.user ? AppStore.user.user.id.toString() : null
            }
        });
    }

    public pageView(name: string): void {
        TagManager.dataLayer({
            dataLayer: { page: name }
        });
    }

    public setUser(userId: string | number) {
        TagManager.dataLayer({
            dataLayer: {
                userId: userId.toString()
            }
        });
    }

    public event(events: Dictionary<string>, dataLayer?: Dictionary<any>): void {
        TagManager.initialize({
            gtmId: GTM_ID,
            events: events,
            dataLayer: (dataLayer || {})
        });
    }

    public transaction(args: TransactionArgs): void {
        TagManager.dataLayer({
            dataLayer: {
                event: "transaction",
                transactionId: args.id,
                transactionTotal: args.total,
                transactionAffiliation: args.affiliation || "Web Store",
                transactionTax: args.tax,
                transactionShipping: args.shipping,
                transactionCurrency: args.currency ? args.currency.toUpperCase() : null,
                transactionProducts: args.products
            }
        });
        TagManager.dataLayer({
            dataLayer: {
                transactionId: null,
                transactionTotal: null,
                transactionAffiliation: null,
                transactionTax: null,
                transactionShipping: null,
                transactionCurrency: null,
                transactionProducts: null
            }
        });
    }
}

export default new Analytics();
