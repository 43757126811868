import { reaction } from "mobx";
import { useEffect, useMemo, useState } from "react";
import Analytics from "services/analytics/Analytics";
import { LoginResponse, User } from "../api/GraphqlTypes";
import AppStore from "../stores/AppStore";

type useUserType = [
    User | undefined,
    boolean,
    {
        onLogin: (r: LoginResponse) => void;
        onLogout: () => void;
    }
]

const useUser = (): useUserType => {
    const userStore = useMemo(() => AppStore.user, []);
    const [user, setUser] = useState(() => userStore.user);
    const [loading, setLoading] = useState(() => userStore.loading);    
    
    useEffect(() => {
        const a = reaction(() => userStore.user, () => setUser(userStore.user));
        const b = reaction(() => userStore.loading, () => setLoading(userStore.loading));
        return () => { a(); b(); }
    }, []);

    useEffect(() => {
        user && Analytics.setUser(user.name);
    }, [user]);

    return [user, loading, {
        onLogin: userStore.onLogin,
        onLogout: userStore.onLogout
    }];
}

export default useUser;