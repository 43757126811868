import Layout, { Footer, Header } from 'antd/lib/layout/layout';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import RoutesMenu from './components/Routing/RoutesMenu';
import Home from './pages';
import Store from './pages/store';
import Download from './pages/download';
import Vote from './pages/vote';
import AppStore from './services/stores/AppStore';
import Text from 'antd/lib/typography/Text';
import Analytics from 'services/analytics/Analytics';
import Env from 'env';
import Highscores from 'pages/highscores';
import AppContent from 'AppContent';

Analytics.initialize();

const App: React.FC = () => {
    const history = useHistory();

    useEffect(() => {
        if (!history) return;
        // If we got redirected we might have a message in the query
        AppStore.notifications.flashQuery(query => {
            const success = query.get('success') == "1";
            if (!success) return;

            const items = query.get('items');
            if (items) {
                return {
                    history,
                    key: 'store-success-message',
                    type: 'success',
                    message: <Text strong>Success!</Text>,
                    description: (
                        <>
                            You can claim the following items in-game by using the <Text code>::claim</Text> command.<br /><br />
                            <Text strong>{items}</Text>
                        </>
                    )
                };
            }
            const message = query.get('message');
            if (message) {
                const title = query.get('title');
                return {
                    history,
                    key: 'query-success-message',
                    type: 'success',
                    message: <Text strong>{title || "Success!"}</Text>,
                    description: message
                }
            }
        });
    }, [history]);

    const search = new URLSearchParams(window.location.search);
    const enableStore = Env.ENABLE_STORE || search.get("force-enable-store") === "5";

    return (
        <BrowserRouter>
            <Layout
                className="layout"
                style={{
                    paddingTop: "64px"
                }}
            >
                <Header id="app-header">
                    <div className="blured-underlay" />
                    <div className="logo" />
                    <RoutesMenu
                        items={[
                            { label: "HOME", path: "/", asPath: "" },
                            { label: "DOWNLOAD", path: "/download" },
                            ...(enableStore ? [{ label: "STORE", path: "/store" }] : []),
                            { label: "VOTE", path: "/vote" },
                            { label: "HIGHSCORES", path: "/highscores" },
                            { label: "DISCORD", href: Env.DISCORD_LINK }
                        ]}
                        style={{ background: "none", textAlign: 'center' }}
                    />
                </Header>

                <AppContent>
                    <Switch>
                        <Route path="/download" component={Download} />
                        <Route path="/store" component={Store} />
                        <Route path="/vote" component={Vote} />
                        <Route path="/highscores" component={Highscores} />
                        <Route path="/" component={Home} exact />
                    </Switch>
                </AppContent>

                <Footer
                    style={{
                        textAlign: "center",
                        position: "relative",
                        backgroundColor: "transparent",
                        zIndex: 100
                    }}
                >
                    <div className="blured-underlay" />
                    {Env.APP_NAME} ©{(new Date().getFullYear())}
                </Footer>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
