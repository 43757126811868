import moment from "moment";
import { useEffect, useMemo, useState } from "react";

function getCountdown(time: moment.Moment | null) {
    if (!time) return null;
    const now = moment.utc();
    if (now.isAfter(time)) return "00:00:00";
    const diff = time.diff(now);
    const duration = moment.duration(diff);
    return (duration as any).format("hh:mm:ss");
}

const useCountdown = (target?: string | Date | moment.Moment) => {
    const time = useMemo(() => {
        if (!target) {
            return  null;
        }
        const t = moment.utc(target);
        if (!t.isValid()) {
            return null;
        }
        return t;
    }, [target]);

    const [fromNow, setFromNow] = useState(getCountdown(time));

    useEffect(() => {
        if (!time) {
            return;
        }
        const int = setInterval(() => setFromNow(getCountdown(time)), 1000);
        return () => clearInterval(int);
    }, [time]);

    return {
        countdown: fromNow,
        countdownEnded: fromNow === "00:00:00"
     };
}

export default useCountdown;